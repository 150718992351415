import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  //require("./animations");
  //require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  //require("./pop-up");
  require("./simple-lightbox");
  require("./sort");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  // O wodach
  var hoverOrClick = function () {
    let selector_id = $(this).attr('id');
    $('.water__selector').removeClass('active');
    $(this).addClass('active');

    $('.water__info').removeClass('active');
    $('#c_' + selector_id).addClass('active');
  }
  $('.water__selector').click(hoverOrClick).hover(hoverOrClick)

  
  // Mapka
  $('.map__places .close').on('click', function () {
    $('.map__places ul').slideToggle('fast');
    $('.map__places .close').fadeToggle('fast');
    $('.map__places .button').fadeToggle('fast');
  });
  $('.map__places .button').on('click', function () {
    $('.map__places ul').slideToggle('fast');
    $('.map__places .close').fadeToggle('fast');
    $('.map__places .button').slideToggle('fast');
  });
  if ($(window).width() < 992) {
    $('.map__places ul').slideToggle('fast');
    $('.map__places .close').fadeToggle('fast');
    $('.map__places .button').slideToggle('fast');
  }


  // Lista mieszkan
  let defaultimg = $('.flatItem.active').attr('data-img');
  $('.offer__preview .image').attr("src",defaultimg);
  $('.offer__preview a').attr("data-lightbox",defaultimg);
  $('.offer__preview a').attr("href",defaultimg);


  var hoverOrClick = function () {
    let img = $(this).attr('data-img');
    $('.flatItem').removeClass('active');
    $(this).addClass('active');

    $('.offer__preview .image').attr("src",img);
    $('.offer__preview .image').attr("srcset",'');
    $('.offer__preview a').attr("data-lightbox",img);
    $('.offer__preview a').attr("href",img);
  }
  $('.flatItem').click(hoverOrClick).hover(hoverOrClick);



  

  // Parallax
  var winScrollTop=0;

  $.fn.is_on_screen = function(){    
      var win = $(window);
      var viewport = {
          top : win.scrollTop(),
          left : win.scrollLeft()
      };
      //viewport.right = viewport.left + win.width();
      viewport.bottom = viewport.top + win.height();
  
      var bounds = this.offset();
      //bounds.right = bounds.left + this.outerWidth();
      bounds.bottom = bounds.top + this.outerHeight();
  
      return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
  };
  
  function parallax() { 
    var scrolled = $(window).scrollTop();
    $('.parallax_scroll').each(function(){
    
       if ($(this).is_on_screen()) {	
             var firstTop = $(this).offset().top; 
            var $span = $(this);
            var moveTop = (firstTop-winScrollTop)*0.1 //speed;
            $span.css("transform","translateY("+-moveTop+"px)");
       }
       
    });
  }
  
  $(window).scroll(function(e){
    winScrollTop = $(this).scrollTop();
    parallax();
  });

});
